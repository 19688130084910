<template>
  <div class="page">
    <div class="page-main">
      <!--      页面-->
      <div class="page-header">
        <el-button
          id="btn-create"
          type="primary"
          size="small"
          @click="showPopup('create')"
        >create</el-button>
        <el-button
          id="btn-update"
          type="primary"
          size="small"
          @click="showPopup('update')"
          :disabled="updateButtonDisabled"
        >update</el-button>
        <el-button
          id="btn-delete"
          type="primary"
          size="small"
          @click="delete_()"
          :disabled="deleteButtonDisabled"
        >delete</el-button>
      </div>
      <div class="table">
        <table-pagination
          :tableData="table.data"
          :columnData="table.column"
          :total="table.total"
          :currentPage.sync="table.currentPage"
          :pageSize.sync="table.pageSize"
          selectionShow
          @selection-change="selectionChange"
          @changeCurrentPage="refresh"
        >
          <!-- 多选框 -->
          <el-table-column
            :if="selectionShow"
            type="selection"
            min-width="40px"
          ></el-table-column>
        </table-pagination>
      </div>
      <!--      弹窗-->
      <el-dialog
        class="create"
        v-if="showDeviceDialog"
        :visible.sync="showDeviceDialog"
        title="Device Detail"
        :before-close="closePopup"
      >
        <el-form
          ref="deviceForm"
          :model="deviceForm"
          :rules="rules"
          label-width="150px"
        >
          <el-form-item :label="$t('device.macAddress')" prop="mac">
            <input-mac v-model="deviceForm.mac"></input-mac>
          </el-form-item>
          <el-form-item :label="$t('device.model')" prop="model">
            <select-model
              v-model="deviceForm.model"
              clearable
              :placeholder="$t('sipAccount.selectModel')"
            />
          </el-form-item>
          <el-form-item :label="$t('device.snCode')" prop="sn">
            <el-input v-model="deviceForm.sn"></el-input>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <el-button
            class="footer-btn"
            type="primary"
            plain
            @click="closePopup"
          >Cancel</el-button>
          <el-button
            class="footer-btn"
            type="primary"
            @click="submitForm"
          >Submit</el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios.js'
import { macFormat } from '@/plugins/methods'
import { TablePagination, InputMac, SelectModel } from '@/components'
import { createModel, updateModel } from '@/api/model'

export default {
  name: 'DeviceFilter',
  components: {
    TablePagination,
    InputMac,
    SelectModel
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('device.macAddressRule')))
      } else if (!macFormat(value)) {
        callback(new Error(this.$t('device.macAddressRule')))
      } else {
        callback()
      }
    }
    return {
      updateButtonDisabled: true,
      deleteButtonDisabled: true,
      selectionShow: false,
      table: {
        data: [],
        column: [
          {
            label: 'mac',
            prop: 'mac',
            minWidth: 150,
          },
          {
            label: 'sn',
            prop: 'sn',
            minWidth: 150,
          },
          {
            label: 'model',
            prop: 'model',
            minWidth: 150,
          }
        ],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      searchKey: String,
      showDeviceDialog: false,
      selected: null,
      dialogType: null,  // create-新增 update-修改
      // 设备
      deviceForm: {
        id: null,
        model: null,
        sn: null,
        mac: null
      },
      rules: {
        mac: {
          required: true,
          validator: validatePass,
          trigger: 'change',
        },
        model: {
          required: true,
          trigger: 'change',
        },
        sn: {
          required: true,
          message: this.$t('device.snCodeRule'),
          trigger: 'blur',
        },
      },
    }
  },
  methods: {
    refresh() {
      this.clean()
      this.read()
    },
    create() {
      this.$refs['deviceForm'].validate((valid) => {
        if (valid) {
          createModel(this.deviceForm)
            .then(() => {
              this.refresh()
              this.closePopup('create')
              this.$message.success('successful')
            })
        } else {
          return false;
        }
      });
    },
    read() {
      let params = {
        searchKey: this.searchKey,
        pageSize: this.table.pageSize,
        currentPage: this.table.currentPage
      }
      axios.post('/model/read', params).then((data) => {
        this.table.data = data.data.result.rows
        this.table.total = data.data.result.totalRows
      })
    },
    update() {
      updateModel(this.deviceForm)
        .then(() => {
          this.dialog.update = {}
          this.closePopup('update')
          this.$message.success('successful')
          this.refresh()
        })
    },
    delete_() {
      let param = {
        ids: []
      }
      for (const ele of this.selected) {
        param.ids.push(ele.id)
      }
      axios.post('/model/delete', param).then(() => {
        this.$message.success('successful')
        this.refresh()
      })
    },
    showPopup(name) {
      if (name === 'update') this.deviceForm = this.selected[0]
      this.dialogType = name
      this.showDeviceDialog = true
    },
    // 提交
    submitForm() {
      switch (this.dialogType) {
        case 'create':
          this.create()
          break
        case 'update':
          this.update()
          break
      }
    },
    // 关闭弹窗
    closePopup() {
      this.clean()
      this.showDeviceDialog = false
    },
    selectionChange(selected) {
      this.selected = selected
      if (this.selected.length === 1) {
        this.updateButtonDisabled = false
      } else {
        this.updateButtonDisabled = true
      }

      if (this.selected.length < 1) {
        this.deleteButtonDisabled = true
      } else {
        this.deleteButtonDisabled = false
      }
    },
    clean() {
      this.deviceForm = {
        id: null,
        model: null,
        sn: null,
        mac: null
      }
    }
  },
  mounted() {
    this.refresh()
  },
}
</script>

<style lang="scss" scoped>
.page-main {
  .el-dialog {
    //width: 30%;
  }
}
</style>
